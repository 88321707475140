import Hero from "../components/Hero"

import "../css/Home.css"

const Home = () => {
  return <>
    <Hero />
    <main className="home">
      <section>
        <div className="before">
          <h3>Před masáží</h3>
          <article>
            <p>Před tím, než se vypravíte na masáž, je vhodné dát si teplou koupel nebo sprchu. Tělo se prohřeje
              a
              svaly uvolní. Navíc, tělo snáze absorbuje masážní prostředky, mající často s léčivé účinky.
              Přijďte
              s dostatečným časovým předstihem, ale nespěchejte. Vaše psychika tak bude klidnější a tělo lépe
              připravené. Na masáž nechoďte s plným žaludkem, doporučuje se alespoň hodinu před masáží nejíst.
              Nepijte alkoholické nápoje a neužívejte omamné látky. Na léky nás prosím dopředu upozorněte.
              Vždy se
              seznamte s kontraindikacemi pro jednotlivé typy masáží. Před masáží je vhodné odložit veškeré
              šperky, prsteny, řetízky a dlouhé vlasy sepnout sponou nebo gumičkou.</p>
            <p class="red">Nechoďte na masáž, pokud trpíte zánětlivým onemocněním, dokonce i nachlazením.</p>
            <p>Při masáži dochází k silnému prokrvení orgánů a začínající nemoc se tím může výrazně urychlit.
              Pokud
              cítíte, že „na vás něco leze“, raději nám to sdělte a případně se domluvíme na jiném termínu.
            </p>
            <div class="flex2">
              <h4>Masáž celého těla konzultujte prosím s lékařem při:</h4>
              <ul>
                <li>vysokém, nízkém či kolísavém tlaku</li>
                <li>cukrovce</li>
                <li>epilepsii</li>
                <li>onemocnění srdce</li>
                <li>poruše krvetvorby, srážlivosti krve</li>
              </ul>
            </div>
          </article>
        </div>
        <div className="after">
          <h3>Po masáži</h3>
          <article>
            <p>Po masáži si pravděpodobně budete chtít ještě trochu poležet, odpočinout. Citliví lidé mohou po
              masáži pociťovat lehkou únavu, dochází totiž ke zrychlení metabolizmu.Při masáži dochází k
              aktivaci
              lymfatického systému a souběžně s tím i ke zvýšenému uvolnění toxinů. Proto dostatečně doplňte
              tekutiny (nejlépe pramenitou vodu), aby se tyto toxické látky snáze vyplavily z těla. Je vhodné
              vyhnout se alkoholickým nápojům.</p>
            <p class="red">Po masáži je dobré držet se v teple, aby nedošlo k prochlazení uvolněných svalů.</p>
            <p>Při prochlazení se svaly stáhnou a vznikají svalové spazmy a účinek masáží je tímto efektem
              výrazně
              snížen. Může se stát, že dojde i ke zhoršení stavu svalové hmoty. Je lepší nevystavovat se po
              12-24
              hodin zvýšené fyzické námaze.</p>
            <h4>Jak často se může chodit na masáž?</h4>
            <p>Je důležité chodit prostě pravidelněji. 1x za 4-6 týdnů – v případě, že ke mě chodíte již déle,
              nemáte žádné extra svalové problémy, nic Vás nikde nebolí, tak jako prevence a regenerace
              postačí i
              jedna návštěva za měsíc, toto berte prosím jako doporučené minimum.</p>
          </article>
        </div>
      </section>
    </main>
  </>
}

export default Home