import "../css/Pricing.css"

const Pricing = () => {
  return <>
    <div className="pricing">
      <table>
        <tr>
          <th>Masáž</th>
          <th>Délka</th>
          <th>Cena</th>
        </tr>
        <tr>
          <td>Masáž klasická, relaxační, sportovní</td>
          <td>50-60 minut</td>
          <td>350,- Kč</td>
        </tr>
        <tr>
          <td>Masáž celého těla</td>
          <td>120 minut</td>
          <td>750,- Kč</td>
        </tr>
        <tr>
          <td>Masáž lávovými kameny</td>
          <td>60 minut</td>
          <td>500,- Kč</td>
        </tr>
        <tr>
          <td>Relaxační masáž s prvky havajské masáže Lomi-lomi</td>
          <td>60 minut</td>
          <td>350,- Kč</td>
        </tr>
        <tr>
          <td>Breussova masáž  a balneo (rašelinové obklady)</td>
          <td>75 minut</td>
          <td>500,- Kč</td>
        </tr>
        <tr>
          <td>Breussova masáž bez obkladu z rašeliny</td>
          <td>60 minut</td>
          <td>350,- Kč</td>
        </tr>
        <tr>
          <td>Teplé rašelinové obklady bez masáže</td>
          <td>30 minut</td>
          <td>200,- Kč</td>
        </tr>
      </table>
    </div>
  </>
}

export default Pricing