import "../css/Navbar.css"

const Navbar = () => {
  return <>
    <nav>
      <a href="/">Domů</a>
      <a href="/offer">Nabídka</a>
      <a href="/pricing">Ceník</a>
      <a href="/contacts">Kontakty</a>
    </nav>
  </>
}

export default Navbar