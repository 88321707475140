import "./../css/Contact.css"

const Contact = () => {
  return <>
    <main className='contact'>
      <section class="contact" id="kontakty">
        <div>
          <article>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg>
            <div>
              <h3>Adresa</h3>
              <a href="https://goo.gl/maps/GCrpcaiqjiKH2TQ16" target="blank">Oldřiš 266</a>
            </div>
          </article>
          <article>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>
            <div>
              <h3>Telefon</h3>
              <a href="tel:+420 723 959 091"> 723 959 091</a>
            </div>
          </article>
          <article>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" /></svg>
            <div>
              <h3>Email</h3>
              <a href="mailto:Koutni266@seznam.cz">Koutni266@seznam.cz</a>
            </div>
          </article>
          <article>
          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/></svg>
            <div>
              <h3>Facebook</h3>
              <a href="https://www.facebook.com/people/Mas%C3%A1%C5%BEe-v-Old%C5%99i%C5%A1i/100095378716984/" target="blank" rel="noreferrer">Masáže v Oldřiši</a>
            </div>
          </article>
          <article>
          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H384h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V384c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H384 96zm0 384H352v64H96c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16zm16 48H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg>
            <div>
              <h3>Reservio</h3>
              <a href="https://masaze-oldris-romana-koutna.reservio.com" target="_blank" rel="noreferrer">Reservio</a>
            </div>
          </article>
        </div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1252.8675576576513!2d16.197174188220977!3d49.72663863972648!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470d90ed27757067%3A0xc79dc654477d1d38!2zT2xkxZlpxaEgMjY2LCA1NjkgODIgT2xkxZlpxaE!5e0!3m2!1scs!2scz!4v1686059746684!5m2!1scs!2scz" width="100%" height="350" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="map"></iframe>
      </section>
    </main>
  </>
}

export default Contact