import "../css/Hero.css"

const Hero = () => {
  return <>
    <div className="hero">
      <img src={require('../img/received_204354099341334.jpeg')} alt=""/>
      <div>
        <h1>Masáže Romana Koutná</h1>
        <p>Masáže v příjemném prostředí masérny v Oldřiši</p>
      </div>
    </div>
  </>
}

export default Hero