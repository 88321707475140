import {BrowserRouter, Route, Routes} from "react-router-dom"

import Home from "./pages/Home"
import Offer from "./pages/Offer"
import Pricing from "./pages/Pricing"
import Contact from "./pages/Contact"
import Error from "./pages/Error"
import SharedLayout from "./pages/SharedLayout";
import './App.css';

function App() {
  return <>
    <BrowserRouter>
      <Routes>
        <Route path ="/" element={<SharedLayout/>} >
          <Route index element={< Home/>} />
          <Route path ="/offer" element={<Offer/>} />
          <Route path ="/pricing" element={<Pricing/>} />
          <Route path ="/contacts" element={<Contact/>} />
          <Route path="*" element={<Error/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  </>;
}

export default App;
