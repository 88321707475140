import "../css/Footer.css"

const Footer = () => {
  return <>
    <footer>
      <div>
        <div>
          <p>Masáže Romana Koutná</p>
          <p>IČ: 17141320</p>
          <p>Oldřiš 266</p>
          <a href="tel:+420 723 959 091">723 959 091</a>
          <a href="mailto:Koutni266@seznam.cz">Koutni266@seznam.cz</a>
        </div>
      </div>
      <div className="author">
        <p>Tento web vytvořil <a href="https://www.pincek.eu" target="_blank" rel="noreferrer">Jan Koutný</a></p>
      </div>
    </footer>
  </>
}

export default Footer