import { useState } from "react"
import offer from "../data"
import massages from "../massages"

import "../css/Offer.css"

const Offer = () => {

  const [typeOfMassage, setTypeOfMassage] = useState("Klasická a relaxační masáž")

  const finalMassage = offer.filter( (oneMassage) => {
    return oneMassage["title"] === typeOfMassage
  })
  
  return <main className="offer">
    <div className="massageButton">
    {
      massages.map( (oneMassageTit) => {
        return <p onClick={ () => setTypeOfMassage(oneMassageTit)}>{oneMassageTit}</p>
      })
    }
    </div>
    {
      finalMassage.map( (oneMassage) => {
        const {id, title, text, image} = oneMassage

        return <div key={id} className="massageDesc">
          <h2>{title}</h2>
          <div className="row">
            <p>{text}</p>
            <img src={image} alt=''></img>
          </div>
        </div>
      })
    }
  </main>
}

export default Offer