import clasic from "./img/klasická masáž.jpg"
import breussova from "./img/breussova masáž.jpg"
import hawaj from "./img/hawajská masáž.jpg"
import rasel1 from "./img/rašelina 1.jpg"

const offer = [
    {
        id: 0,
        title: "Klasická a relaxační masáž",
        text: "Dotek je jedním ze smyslů, kterým jako prvním vnímáme svět a to ještě před narozením. Je to velmi důležitý vjem při stresovém napětí, protože dotekem působíme na nervový systém a to je hlavní význam masáže. Masáží se dá “pohladit” nejen tělo, ale i duše. Lidské tělo potřebuje lidský dotek, jeho léčivá moc odstraňuje stres a dodává blahodárný pocit zdraví. Klasická masáž odstraňuje únavu, stres, nespavost a bolesti. Klasická relaxační (regenerační) masáž uvolňuje masírované svalstvo, povzbuzuje krevní oběh, tím obohacuje krev kyslíkem, zlepšuje výživu buněk a zvyšuje elasticitu svalstva. V rovině duševní má klasická masáž blahodárný vliv na nervovou soustavu a celkově pozitivně ovlivňuje psychický stav člověka. Odstraňuje únavu, stres, nespavost, bolesti hlavy, navozuje příjemné pocity a působí velmi relaxačně. Klasická masáž je prováděna na celé tělo nebo jen na vybrané části (záda, krk, šíje, horní a dolní končetiny…). Klasická masáž je vhodná při přepracovanosti, stresu či bolestech zad. Při této masáži se využívá velká škála hmatů a tahů, které mají blahodárný účinek na masírovaného. Přispívá zejména k obnovení zdraví a správné funkci svalů a kloubů. Relaxační masáž se doporučuje jako pravidelná aplikace neboť dochází k velice podnětným účinkům na krevní oběh, metabolismus, vylučovací systém i centrální nervový systém. Klasická masáž hraje důležitou roli zejména při prevenci řady onemocnění pohybových. Má velice blahodárný relaxační účinek, neboť při masáži se masírovaný velice příjemně uvolní a tak působí i na psychiku masírovaného.",
        image: clasic
    },
    {
        id: 1,
        title: "Rekondiční a sportovní masáž",
        text: "Zlepšuje sportovní výkon, podporuje relaxaci a regeneraci svalů po zvýšené aktivitě. K čemu jsou vhodné masáže, když zrovna nemáte žádné bolesti? Masážní hmaty (doteky) působí nejen na naše tělo, ale i na duši a následně dochází k vyplavování hormonů, jako jsou endorfiny, oxytocin, serotonin a dopamin. Tyto hormony redukují citlivost na bolest a přispívají k dobré náladě a vyrovnanosti. Z lékařského hlediska masáže představují nenahraditelnou součást regeneračního procesu v organizmu. Dobrá masáž dokáže zrychlit regeneraci a vést k zrychlení ozdravných procesů. Je alternativní medicínou a ti kdo jí pravidelně aplikují, udržují své zdraví na vrcholu.",
    },
    {
        id: 2,
        title: "Masáž lávovými kameny",
        text: "Tato masáž patří mezi velmi populární a oblíbené terapie, které slouží jak pro celkovou relaxaci, tak pro regeneraci organismu. Ne náhodou je nazývána „královnou mezi masážemi“. Aplikuje se pomoci speciálních masážních hmatů, teplých lávových kamenů, spolu s aromatickými oleji. Stimuluje krevní oběh a lymfatický systém. Hluboce relaxuje a harmonizuje. Postupně prohřívá celé tělo. Pomáhá detoxikaci organismu. Vyrovnává energii v těle. Horké lávové kameny mají schopnost svaly uvolňovat a „vytáhnout“ z nich bolest a negativní energii.",
    },
    {
        id: 3,
        title: "Masáž Lomi- lomi",
        text: "Říká se o ní, že je perla mezi masážemi. Svým rytmickým a energickým potenciálem vyvolávají vysoký relaxační účinek. Navíc umocňuje pocit klidu, pohody, štěstí a odpočinku. Masérka nepoužívá jen prsty a dlaně, ale především celé předloktí s využitím vlastní váhy a plynulých pohybů celého těla. To poskytuje stimulaci pro krevní oběh a celkový lymfatický systém, hluboce harmonizuje tělo i duši, odstraňuje svalovou bolest, únavu a napětí, uvolňuje bolestivá kloubní spojení a napomáhá maximální detoxikaci Vašeho organismu.. Při pravidelném opakování masáží dochází k rychlejší regeneraci tkání a tím i předcházení obtíží.  Relax a uvolnění jsou její hlavní znaky.",
        image: hawaj
    },
    {
        id: 4,
        title: "Bresussova masáž",
        text: "Jde o jemnou, citlivou, energeticko-manuální masáž zad v oblasti páteře, která uvolní tělesné, psychické i duševní blokády. Breussova masáž významným způsobem regeneruje meziobratlové ploténky a tak je klíčem k řešení mnoha problémů v pohybovém aparátu, obzvláště v oblasti kříže a beder, mimo jiné vede také k odbourání hluboko uložených psychických problémů.",
        image: breussova
    },
    {
        id: 5,
        title: "Rašelinové obklady",
        text: "Rašelinový zábal je jedna z nejuznávanějších forem tepelné terapie, při které dochází k uvolnění svalových kontrakcí, detoxikaci organismu a příjemné relaxaci celého těla. Forma jednorázových aplikací obsahujících velké množství organických a minerálních látek, které se za pomoci tepelné podpory dostávají do našeho těla, mají rovněž blahodárný vliv na pokožku. Rašelinové obklady se kombinují s Bresussovou  nebo relaxační masáží, popřípadě je možno aplikovat obklad bez masáže.",
        image: rasel1,
    },
]

export default offer